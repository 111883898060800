<template>
  <span>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="showDelete = true">
          <v-list-item-action>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-action>
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <DeleteWorkorder
      :show.sync="showDelete"
      :jobnumber="$route.params.jobnumber"
      @onDelete="$router.replace('/')"
    ></DeleteWorkorder>
  </span>
</template>

<script>
import DeleteWorkorder from "@/components/DeleteWorkorder";

export default {
  components: { DeleteWorkorder },
  data() {
    return {
      showDelete: false,
    };
  },
};
</script>

<style>
</style>