<template>
  <v-dialog v-model="show" persistent width="400">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        <h5>Add tools</h5>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:show', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  label="Tool serial number"
                  :items="tools"
                  :rules="[withinCal]"
                  outlined
                  dense
                  v-model="tool"
                  return-object
                  item-text="serialNumber"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Description"
                  readonly
                  disabled
                  outlined
                  dense
                  v-model="tool.description"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Tool ID"
                  readonly
                  disabled
                  outlined
                  dense
                  v-model="tool.id"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Next calibration"
                  readonly
                  disabled
                  outlined
                  dense
                  v-model="formattedDate"
                  :rules="[withinCal]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('update:show', false)">cancel</v-btn>
        <v-btn color="primary" @click="save">
          <v-icon left>mdi-content-save</v-icon>save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      Tool added to {{ $route.params.title }}
      <template v-slot:action>
        <v-btn color="pink" @click="snackbar = false">dismiss</v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  props: {
    show: Boolean,
    existingTools: Array,
  },
  data() {
    return {
      tools: [],
      tool: {},
      snackbar: false,
      loading: false,
    };
  },
  created() {
    this.populate();
  },
  methods: {
    async populate() {
      const r = await axios.get("/api/tools");
      this.tools = r.data.filter((x) => {
        return x.serialNumber;
      });
    },
    findTool(e) {
      const match = this.tools.find((x) => this.searchFn(x, e.target.value));
      console.log(match);
    },
    searchFn(tool, v) {
      return (
        tool.assetId == v ||
        tool.serialNumber == v ||
        tool.id == v ||
        tool.vendorControl == v
      );
    },
    async save() {
      // if (!this.$refs.form.validate()) return;
      // this.loading = true;
      // const post = {
      //   jobnumber: this.$route.params.title,
      //   id: this.tool.id,
      //   serialNumber: this.tool.serialNumber,
      //   description: this.tool.description,
      //   calibrationDate: this.tool.calibrationDate,
      // };
      // await fetch("/api/workorders/tools", {
      //   method: "post",
      //   body: JSON.stringify(post),
      //   headers: { "content-type": "application/json" },
      // });
      // this.loading = false;
      // this.snackbar = true;
      // this.tool = {};
      // this.$refs.form.resetValidation();
      // this.$emit("updated");
    },
  },
  computed: {
    withinCal() {
      if (!this.tool.id) return "Required.";
      let ids = [];
      if (this.existingTools) {
        ids = this.existingTools.map((x) => x.toolId);
      }
      if (ids.indexOf(this.tool.id) > -1) return "Tool already used.";
      return moment(this.tool.nextCalibration) > moment()
        ? true
        : "Tool calibration overdue.";
    },
    formattedDate() {
      if (!this.tool || !this.tool.nextCalibration) return "";
      return moment(this.tool.nextCalibration).format("YYYY-MM-DD");
    },
  },
};
</script>