<template>
  <v-text-field
    :label="label"
    :dense="dense"
    :outlined="outlined"
    v-model="cardId"
    @change="onChange"
    @click="startNfc"
    clearable
    :rules="rules"
    :autofocus="autofocus"
    append-icon="mdi-nfc-variant"
    :hide-details="hideDetails"
    :loading="loading"
    :disabled="loading"
    ref="k"
    :hint="nfcText"
    autocomplete="off"
  ></v-text-field>
</template>

<script>
/*global NDEFReader*/

import { Rfid } from "@/rfid.js";
import _ from "lodash";

export default {
  props: {
    label: String,
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    rules: Array,
    hideDetails: Boolean,
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardId: "",
      nfcText: "",
      nfcResult: "",
    };
  },
  mounted() {
    this.$refs.k.focus();
  },
  methods: {
    startNfc() {
      if (!("NDEFReader" in window))
        return (this.nfcText = "NFC not supported.");

      const ndef = new NDEFReader();

      ndef
        .scan()
        .then(() => {
          this.nfcText = "NFC started. Tap your badge.";
          ndef.onreading = (event) => {
            this.nfcResult = event.serialNumber;
            this.cardId = event.serialNumber;
            this.$emit("onReadHex", this.cardId);
          };
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    onChange: _.debounce(function () {
      if (this.cardId.length < 10) return;
      let hex = new Rfid(this.cardId).toHex();
      if (hex.length < 11) return;
      this.$emit("onReadHex", hex);
      this.cardId = "";
    }, 0),
    resetForm() {
      this.cardId = "";
    },
    focus() {
      this.$refs.k.focus();
    },
  },
};
</script>

<style></style>