import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import IdleVue from "idle-vue";
import "./registerServiceWorker";

const idleTime = 1000 * 60 * 2;

Vue.use(IdleVue, { store, idleTime, startAtIdle: false });

require("@/assets/style.css");

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
