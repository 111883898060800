<template>
  <span>
    <v-btn icon @click="show = !show"> <v-icon>mdi-eye-outline</v-icon> </v-btn>
    <v-dialog v-model="show" width="1000" scrollable>
      <v-card>
        <v-card-title>
          <h5>Template Details</h5>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <code>
            <pre>{{ template }}</pre>
          </code>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    template: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {},
};
</script>

<style>
</style>