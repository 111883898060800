var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"color":"primary","dense":"","app":"","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.goHome}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.populate}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('UserMenu',{attrs:{"display":"name"}})],1),_c('v-main',[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.jobnumber",fn:function(ref){
var item = ref.item;
return [(item.approver)?_c('router-link',{attrs:{"to":("wo/" + (item.jobnumber))}},[_vm._v(" "+_vm._s(item.jobnumber)+" ")]):_c('span',[_vm._v(_vm._s(item.jobnumber))])]}},{key:"item.requiredInspector",fn:function(ref){
var item = ref.item;
return [(item.complete)?_c('span',[_vm._v(" Complete ")]):(item.requiredInspector)?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(" Required ")]):_c('v-chip',{attrs:{"small":"","color":"success","dark":""}},[_vm._v("In process")])]}},{key:"item.reviewer",fn:function(ref){
var item = ref.item;
return [(item.reviewer)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),_vm._v(" "+_vm._s(item.reviewer.user.name)+" ")]}},{key:"item.approver",fn:function(ref){
var item = ref.item;
return [(item.approver)?_c('span',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(((item.approver.user.firstName) + " " + (item.approver.user.lastName)))+" ")],1):_c('Approver',{attrs:{"jobnumber":item.jobnumber},on:{"updated":function($event){item.approver = $event}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.consoleLog(item)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('DeleteWorkorder',{attrs:{"jobnumber":item.jobnumber,"show":_vm.showDelete},on:{"update:show":function($event){_vm.showDelete=$event},"onDelete":_vm.populate}})]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"800","persistent":"","scrollable":""},model:{value:(_vm.peek),callback:function ($$v) {_vm.peek=$$v},expression:"peek"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Workorder Info "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.peek = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('pre',[_vm._v(_vm._s(_vm.pre))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }