<template>
  <div>
    <v-card :disabled="lastStepIncomplete">
      <v-toolbar
        dense
        flat
        @click="expanded = !expanded"
        style="cursor: pointer"
      >
        <h5>Task #{{ index + 1 }}</h5>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!task.mechanic.complete && !expanded"
          color="primary"
          small
          @click.stop="stamp"
          :loading="loading"
          :disabled="disabled"
        >
          Technician
        </v-btn>
        <v-icon class="mr-2" color="green" v-if="task.mechanic.complete">
          mdi-check
        </v-icon>
      </v-toolbar>
      <span v-if="shouldExpand">
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="8">
                <span class="font-weight-medium">{{ task.title }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols>
                <v-list dense v-if="task.mechanic.complete">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Technician </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ task.mechanic.user.firstName }}
                        {{ task.mechanic.user.lastName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Timestamp</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ parseDate(task.mechanic.timestamp) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Results</v-list-item-subtitle>
                      <v-list-item-title v-if="task.mechanic.notApplicable">
                        N/A
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{ task.mechanic.results || "-" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-textarea
                  v-else
                  label="Results"
                  outlined
                  dense
                  hide-details
                  :rows="3"
                  auto-grow
                  v-model="results"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider v-if="!lastStepIncomplete"></v-divider>
        <v-card-actions v-if="!lastStepIncomplete">
          <v-checkbox
            :disabled="task.mechanic.complete"
            class="pa-0 ma-0"
            hide-details
            label="Not Applicable"
            v-model="task.mechanic.notApplicable"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click="stamp"
            :loading="loading"
            :disabled="disabled"
          >
            <v-icon left v-if="!!task.mechanic.complete"> mdi-check </v-icon>
            Technician
          </v-btn>
        </v-card-actions>
      </span>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  props: {
    task: Object,
    index: Number,
    prevTask: Object,
  },
  data() {
    return {
      loading: false,
      results: "",
      expanded: false,
    };
  },
  watch: {
    "$route.query.task"(val) {
      this.expanded = val == this.index + 1;
    },
  },
  methods: {
    async stamp() {
      this.loading = true;
      const r = await axios.put(`/api/workorders/${this.jobnumber}`, {
        processIndex: this.process,
        taskIndex: this.index,
        results: this.results,
        notApplicable: this.task.mechanic.notApplicable,
        user: this.$store.state.jwt.user,
      });
      this.loading = false;
      this.task.mechanic = r.data;
      this.$emit("onStamp", r.data);
      this.expanded = false;
    },
    async setNotApplicable(notApplicable) {
      const r = await axios.put(`/api/workorders/${this.jobnumber}`, {
        process: this.process,
        taskIndex: this.index,
        notApplicable,
      });
      console.log(r.data);
    },
    parseDate(date) {
      return moment(date).format("YYYY-MM-DD hh:mm A");
    },
  },
  computed: {
    jobnumber() {
      return this.$route.params.jobnumber;
    },
    process() {
      return +this.$route.query.process;
    },
    lastStepIncomplete() {
      if (!this.prevTask) return false;
      return !this.prevTask.mechanic.complete;
    },
    shouldExpand() {
      return this.expanded;
    },
    user() {
      return this.$store.state.jwt.user;
    },
    disabled() {
      return (
        !!this.task.mechanic.complete ||
        this.user.position.toUpperCase() !== "MECHANIC"
      );
    },
  },
};
</script>

<style>
</style>