<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <v-btn
            color="primary"
            :disabled="
              process.inspector.complete ||
              !allComplete ||
              user.position.toUpperCase() !== 'INSPECTOR'
            "
            @click="stamp"
            :loading="loading"
          >
            <v-icon left v-if="process.inspector.complete"> mdi-check </v-icon>
            inspector
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="process.inspector.complete">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle> Inspector </v-list-item-subtitle>
                <v-list-item-title>
                  {{ process.inspector.user.firstName }}
                  {{ process.inspector.user.lastName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Timestamp</v-list-item-subtitle>
                <v-list-item-title>
                  {{ parseDate(process.inspector.timestamp) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    parseDate(data) {
      return moment(data).format("YYYY-MM-DD hh:mm A");
    },
    async stamp() {
      this.loading = true;
      const r = await axios.put(`/api/workorders/${this.jobnumber}`, {
        inspector: true,
        processIndex: this.$route.query.process,
        user: this.$store.state.jwt.user,
      });
      this.process.inspector = r.data;
      this.loading = false;
      setTimeout(() => {
        this.$router.push({
          query: { process: +this.$route.query.process + 1 },
        });
      }, 600);
    },
  },
  computed: {
    process() {
      return this.$store.state.template.processes[
        this.$route.query.process - 1
      ];
    },
    allComplete() {
      let me = this.process.tasks.every((x) => x.mechanic.complete);
      console.log(me);
      return me;
    },
    user() {
      return this.$store.state.jwt.user;
    },
    jobnumber() {
      return this.$route.params.jobnumber;
    },
  },
};
</script>

<style>
</style>