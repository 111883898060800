<template>
  <div style="height: 100vh">
    <div id="userDiv" v-if="false">
      <UserCard></UserCard>
    </div>
    <v-container fluid fill-height>
      <v-row dense justify="center">
        <v-col cols="12" sm="6" md="3" v-if="!hasUser">
          <Login @hasUser="hasUser = true"></Login>
        </v-col>
        <v-col cols="12" sm="6" md="3" v-else>
          <v-card flat>
            <v-card-title>
              <h5>Workorder Portal</h5>
            </v-card-title>
            <v-card-text>
              <v-text-field
                autofocus
                ref="me"
                label="Job Number"
                v-model="jobnumber"
                outlined
                hide-details
                :disabled="loading"
                @keyup.enter="submit"
                @keyup="error = false"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn icon @click="hasUser = false">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="submit"
                :loading="loading"
                :disabled="error"
              >
                search
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Login from "@/components/Login";
import UserCard from "@/components/UserCard";

async function populate(jobnumber) {
  const r = await axios.get(`/api/workorders/${jobnumber}?approved=true`);
  return r.data.data.workorder;
}

export default {
  name: "Home",
  components: { Login, UserCard },
  data() {
    return {
      hasUser: false,
      jobnumber: "",
      loading: false,
      error: false,
      tag: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    document.title = "Workorder Service";
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title = "Workorder Service";
    next();
  },
  created() {
    this.checkQuery();
    this.clearStore();
    this.deleteAllCookies();
  },
  methods: {
    async checkQuery() {
      if (this.$route.query.redirecttoken) {
        await axios.post("/user/verify-redirect-token", {
          redirectToken: this.$route.query.redirecttoken,
        });
      }
    },
    async submit() {
      this.loading = true;
      const r = await axios.post("/api/search", { value: this.jobnumber });
      if (r.data.err) {
        this.error = true;
        this.jobnumber = "";
        this.$refs.me.focus();
        this.loading = false;
        return;
      }
      this.$store.commit("setJob", r.data);

      const data = await populate(this.jobnumber);
      this.$store.commit("setTemplate", data);
      this.$router.push(`/wo/${this.jobnumber.toUpperCase()}`);
    },
    async findUser() {
      this.loading = true;
      const r = await axios.post("/api/user", { tag: this.tag });
      console.log(r);
    },
    clearStore() {
      this.$store.commit("setJob", null);
      this.$store.commit("setTemplate", null);
      this.$store.commit("setJwt", null);
    },
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.jwt.user;
    },
  },
};
</script>

<style scoped>
#userDiv {
  position: fixed;
  top: 10px;
  right: 10px;
}
</style>