<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text rounded class="mx-1">
        <v-icon left>mdi-account</v-icon>
        <span v-if="display == 'position'">{{ position }}</span>
        <span v-if="display == 'name'">{{ name }}</span>
      </v-btn>
    </template>
    <UserCard></UserCard>
  </v-menu>
</template>

<script>
import UserCard from "@/components/UserCard";
import moment from "moment";

export default {
  components: { UserCard },
  props: {
    display: {
      type: String,
      default: "position",
    },
  },
  data() {
    return {
      menu: false,
      time: "",
    };
  },
  mounted() {},
  computed: {
    img() {
      return this.$store.state.jwt.user.picture;
    },
    name() {
      return (
        this.$store.state.jwt.user.firstName +
        " " +
        this.$store.state.jwt.user.lastName
      );
    },
    employeeId() {
      return this.$store.state.jwt.user.employeeId;
    },
    position() {
      return this.$store.state.jwt.user.position;
    },
    timeStamp() {
      return moment(this.$store.state.jwt.user.loginTime).format();
    },
  },
};
</script>

<style>
</style>