<template>
  <v-dialog v-model="show" width="400" persistent>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        <h5>Delete this job card?</h5>
      </v-card-title>
      <v-card-text>
        <p>Are you sure you want to delete this job card?</p>
        <p>
          Workorder <strong>{{ jobnumber }}</strong> will not be affected. You
          can recreate a new job card once this one is deleted.
        </p>
        <v-text-field
          v-model="answer"
          outlined
          :label="`Enter ${jobnumber} to delete`"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="remove" :disabled="disabled">
          <v-icon left>mdi-delete</v-icon>
          delete
        </v-btn>
        <v-btn color="primary" text @click="$emit('update:show', false)">
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    show: Boolean,
    jobnumber: String,
  },
  data() {
    return {
      loading: false,
      answer: "",
    };
  },
  methods: {
    async remove() {
      this.loading = true;
      await axios.delete("/api/workorders", {
        data: { jobnumber: this.jobnumber },
      });
      this.loading = false;
      this.answer = "";
      this.$emit("onDelete");
      this.$emit("update:show", false);
    },
  },
  computed: {
    disabled() {
      return this.answer !== this.jobnumber;
    },
  },
};
</script>

<style>
</style>