<template>
  <v-card>
    <v-toolbar class="transparent" dense flat>
      <v-chip color="primary">{{ title }}</v-chip>
      <v-spacer></v-spacer>
      <v-progress-linear
        :value="progress"
        :color="progressColor"
        absolute
        bottom
      ></v-progress-linear>
    </v-toolbar>
    <v-container fluid v-if="!previousStepComplete" fill-height>
      <v-row justify="center">
        <v-col cols="auto">
          <v-card>
            <v-card-text> Complete previous process to proceed </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="goback"> go back </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text v-else>
      <v-container fluid>
        <v-row dense justify="center">
          <v-col cols="8" xs="12" sm="12">
            <v-row v-for="(task, index) in process.tasks" :key="index">
              <v-col>
                <Tasks
                  :task="task"
                  :prevTask="process.tasks[index - 1]"
                  :index="index"
                  @onStamp="onStamp($event, index)"
                ></Tasks>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Tasks from "./Tasks";
import moment from "moment";

export default {
  props: {},
  data() {
    return {
      task: 0,
      loading: false,
    };
  },
  components: { Tasks },
  methods: {
    onStamp(obj, index) {
      this.process.tasks[index].mechanic = obj;
    },
    goback() {
      this.$router.replace({
        query: { process: +this.$route.query.process - 1 },
      });
    },
    parseDate(date) {
      return moment(date).format();
    },
  },
  computed: {
    previousStepComplete() {
      if (this.$route.query.process == 1) return true;
      return this.template.processes[this.$route.query.process - 2].inspector
        .complete;
    },

    progress() {
      const prog = this.process.tasks.filter((x) => x.mechanic.complete).length;
      return (prog / this.process.tasks.length) * 100;
    },
    progressColor() {
      if (this.progress < 50) return "error";
      if (this.progress < 75) return "amber";
      if (this.progress == 100) return "green";
      return "yellow";
    },
    jobnumber() {
      return this.$route.params.jobnumber;
    },
    title() {
      return this.process ? this.process.title : "";
    },
    template() {
      return this.$store.state.template;
    },
    process() {
      return this.$store.state.template.processes[
        this.$route.query.process - 1
      ];
    },
  },
};
</script>

<style scoped>
#div {
  width: 100%;
}
</style>