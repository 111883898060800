<template>
  <span>
    <v-navigation-drawer app clipped permanent width="500">
      <Navigation></Navigation>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark dense clipped-left>
      <v-btn icon @click="goHome">
        <v-icon v-if="user.position.toUpperCase() == 'MECHANIC'">
          mdi-home
        </v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </v-btn>
      <JobInfo></JobInfo>
      {{ component }} - {{ jobnumber }}
      <Test :template="template"></Test>
      <v-spacer></v-spacer>
      <v-btn icon @click="prev" :disabled="!hasPrev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      Step {{ process }} of {{ length }}
      <v-btn icon @click="next" :disabled="!hasNext">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <UserMenu></UserMenu>
      <TimeSinceLogin :timeSinceLogin="timeSinceLogin"></TimeSinceLogin>
      <MoreMenu></MoreMenu>
      <v-switch
        v-model="$vuetify.theme.dark"
        class="mt-5"
        :label="`Dark theme: ${$vuetify.theme.dark ? 'on' : 'off'}`"
      ></v-switch>
      <v-progress-linear
        :indeterminate="loading"
        :active="loading"
        absolute
        top
        color="white"
      ></v-progress-linear>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row dense>
          <v-col cols="8">
            <v-row dense>
              <v-col cols="12">
                <Process></Process>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row dense>
              <v-col cols="12">
                <Dashboard></Dashboard>
              </v-col>
              <v-col cols="12">
                <Tools></Tools>
              </v-col>
              <v-col cols="12">
                <InspectorCard></InspectorCard>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog :value="isAppIdle" persistent width="400">
        <v-card>
          <v-card-title>
            <h5>Hello?</h5>
          </v-card-title>
          <v-card-text>
            You have been away for too long.
            <p>
              {{ timer }} second left before being redirected to login page.
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </span>
</template>

<script>
import axios from "axios";
import Process from "./Process";
import Navigation from "./Navigation";
import JobInfo from "@/components/JobInfo";
import UserMenu from "@/components/UserMenu";
import Test from "@/components/Test";
import MoreMenu from "@/components/MoreMenu";
import TimeSinceLogin from "@/components/TimeSinceLogin";
import Dashboard from "./Dashboard";
import Tools from "./Tools";
import InspectorCard from "@/components/InspectorCard";

import moment from "moment";
import jwt from "@/user.js";

async function populate(jobnumber) {
  const r = await axios.get(`/api/workorders/${jobnumber}?approved=true`);
  return r.data.data.workorder;
}

const timeLimit = 30;

export default {
  components: {
    Process,
    Navigation,
    JobInfo,
    UserMenu,
    Test,
    MoreMenu,
    TimeSinceLogin,
    Dashboard,
    Tools,
    InspectorCard,
  },
  data() {
    return {
      loading: false,
      process: 1,
      stop: false,
      timer: timeLimit,
      clock: null,
      time: null,
    };
  },
  created() {
    setInterval(() => {
      this.time = moment();
    }, 1000 / 60);
  },
  mounted() {
    const JWT = jwt();
    this.$store.commit("setJwt", JWT);
  },
  beforeRouteEnter(to, from, next) {
    document.title = to.params.jobnumber;
    populate(to.params.jobnumber).then((data) => {
      console.log(data);
      next((vm) => vm.setData(data));
    });
  },
  beforeRouteUpdate(to, from, next) {
    document.title = to.params.jobnumber;
    this.loading = true;
    populate(to.params.jobnumber).then((data) => {
      this.loading = false;
      this.$store.commit("setTemplate", data);
      next();
    });
  },
  watch: {
    "$route.query.process"(val) {
      this.process = val;
    },
    isAppIdle(val) {
      if (val) {
        this.clock = setInterval(() => {
          this.timer -= 1;
        }, 1000);
      } else {
        clearInterval(this.clock);
        this.timer = timeLimit;
      }
    },
    timer(val) {
      if (val < 0) {
        clearInterval(this.clock);
        this.$store.commit("setUser", null);
        this.$router.replace("/");
      }
    },
    timeSinceLogin(val) {
      if (!this.maxLoginTime) return;
      if (val >= this.maxLoginTime) {
        this.$router.replace("/");
      }
    },
  },
  methods: {
    setData(data) {
      if (!this.user) {
        return this.$router.replace("/");
      }
      this.$store.commit("setTemplate", data);
      this.updatePage();
    },
    goHome() {
      if (this.$store.state.jwt.user.position.toUpperCase() === "MECHANIC")
        return this.$router.replace("/");
      this.$router.replace("/list");
    },
    async populate() {
      this.loading = true;
      const data = await populate(this.$route.params.jobnumber);
      this.$store.commit("setTemplate", data);
      this.loading = false;
      this.updatePage();
    },
    prev() {
      this.process--;
      this.$router.push({ query: { process: this.process } });
    },
    next() {
      this.process++;
      this.$router.push({ query: { process: this.process } });
    },
    updatePage() {
      for (let i = 0; i < this.template.processes.length; i++) {
        if (this.template.processes[i].inspector.complete) this.process++;
      }
      if (this.process >= this.template.processes.length)
        return this.$router.push({ query: { process: 1 } });
      this.$router.push({ query: { process: this.process } });
    },
  },
  computed: {
    user() {
      return this.$store.state.jwt.user;
    },
    jobnumber() {
      return this.$route.params.jobnumber;
    },
    hasPrev() {
      return this.process > 1;
    },
    hasNext() {
      return this.process < this.template.processes.length;
    },
    template() {
      return this.$store.state.template;
    },
    component() {
      return this.template?.component;
    },
    length() {
      return this.template?.processes.length;
    },
    timeSinceLogin() {
      return moment(
        moment(this.time).diff(moment(this.user.loginTime))
      ).valueOf();
    },
    maxLoginTime() {
      const min = this.user.position.toUpperCase() == "MECHANIC" ? 10 : 0;
      return min * 60 * 1000;
    },
  },
};
</script>

<style>
.card-text {
  height: calc(100vh - 48px);
  overflow-y: auto;
}

.bg {
  background-color: rgb(243, 243, 243);
}
</style>