<template>
  <v-card>
    <v-card-title>
      <h5>User Info</h5>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="auto">
            <v-img
              :src="img"
              height="auto"
              width="160"
              aspect-ratio="1"
              contain
            ></v-img>
          </v-col>
          <v-col>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Name </v-list-item-subtitle>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Employee ID </v-list-item-subtitle>
                  <v-list-item-title>{{ employeeId }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Position </v-list-item-subtitle>
                  <v-list-item-title>{{ position }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Login Time </v-list-item-subtitle>
                  <v-list-item-title>{{ timeStamp }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Time Since Login
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ timeSinceLoginFormatted }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <ChangePin></ChangePin>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import ChangePin from "@/components/ChangePin";

export default {
  components: { ChangePin },
  data() {
    return {
      time: null,
    };
  },
  created() {
    setInterval(() => {
      this.time = moment();
    }, 1000 / 60);
  },
  computed: {
    user() {
      return getUser();
    },
    img() {
      return this.$store.state.jwt.picture;
    },
    name() {
      return (
        this.$store.state.jwt.firstName + " " + this.$store.state.jwt.lastName
      );
    },
    employeeId() {
      return this.$store.state.jwt.employeeId;
    },
    position() {
      return this.$store.state.jwt.position;
    },
    timeStamp() {
      return moment(this.$store.state.jwt.loginTime).format(
        "YYYY-MM-DD hh:mm:ss A"
      );
    },
    timeSinceLogin() {
      return moment(
        moment(this.time).diff(moment(this.$store.state.jwt.loginTime))
      ).valueOf();
    },
    timeSinceLoginFormatted() {
      const timeSinceLogin = moment(this.timeSinceLogin).format("mm:ss");
      this.$emit("timeSinceLogin", timeSinceLogin);
      return timeSinceLogin;
    },
  },
};

function getUser(key = "jwt-workorder-service") {
  const k = document.cookie.split(";").map((x) => {
    const cookie = x.trim();
    const [key, value] = cookie.split("=");
    return { key, value };
  });
  const user = k.find((x) => x.key == key).value;
  const payload = user.split(".")[1];
  const u = JSON.parse(atob(payload));
  return u;
}
</script>

<style>
</style>