<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-chip v-on="on" color="primary">{{ timeSinceLoginFormatted }}</v-chip>
    </template>
    <v-card>
      <v-card-text>
        <v-container fluid>
          You can remain logged in for the maximum of 10 minutes, <br />you will
          be logged out after 2 minutes of inactivity, whichever occurs first.
        </v-container>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: { timeSinceLogin: Number },
  computed: {
    timeSinceLoginFormatted() {
      const timeSinceLogin = moment(this.timeSinceLogin).format("mm:ss");
      this.$emit("timeSinceLogin", timeSinceLogin);
      return timeSinceLogin;
    },
  },
};
</script>

<style>
</style>