<template>
  <v-card flat>
    <v-card-title>
      <h5>
        <v-icon class="mr-2">mdi-account</v-icon>
        {{ user.user }}
      </h5>
    </v-card-title>
    <v-card-text>
      <input type="password" style="display: none" />
      <v-text-field
        v-if="user.hasPin"
        outlined
        label="ใส่รหัส 6 หลัก"
        v-model="pin"
        :loading="loading"
        :disabled="loading"
        autofocus
        hide-details
        type="password"
      ></v-text-field>
      <span v-else>
        <v-text-field
          outlined
          label="สร้างรหัสผ่านเป็นตัวเลข 6 หลัก"
          v-model="newPin"
          :loading="loading"
          :disabled="loading"
          autofocus
          :rules="[rules]"
          type="password"
        ></v-text-field>
        <v-text-field
          outlined
          label="ใส่รหัสผ่านอีกครั้ง"
          v-model="retypePin"
          :disabled="loading"
          :loading="loading"
          :rules="[rules]"
          type="password"
        ></v-text-field>
      </span>
    </v-card-text>
    <v-card-actions>
      <v-btn icon @click="$emit('onError')">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!user.hasPin"
        @click="addPin"
        color="primary"
        :disabled="disabled"
        :loading="loading"
      >
        สร้าง Pin
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      loading: false,
      pin: "",
      newPin: "",
      retypePin: "",
    };
  },
  watch: {
    pin() {
      this.submitPin(this.pin);
    },
  },
  methods: {
    update(data) {
      this.$emit("onUpdate", data);
    },
    async submitPin(data) {
      if (data.length !== 6) return;
      this.loading = true;
      const post = {
        rfid: this.user.rfid,
        pin: data,
      };
      const r = await axios.post("/user/search-rfid-pin", post);
      if (r.data.employeeId) {
        this.$emit("onPinInput", r.data);
      } else {
        this.$emit("onError");
      }
      this.loading = false;
      this.pin = "";
      this.newPin = "";
      this.retypePin = "";
    },
    async addPin() {
      this.loading = true;
      const post = {
        rfid: this.user.rfid,
        pin: this.newPin,
      };
      const r = await axios.put("/user/search-rfid-pin", post);
      if (r.data.success) {
        this.$emit("onPinInput", r.data);
        this.loading = false;
      }
    },
  },
  computed: {
    rules() {
      if (!this.newPin || !this.retypePin) return "";
      if (this.newPin.length !== 6) return "รหัสผ่านต้องมีความยาว 6 ตัว";
      if (this.newPin !== this.retypePin) return "รหัสผ่านไม่ตรงกัน";
      const regex = new RegExp("[^0-9]{6}");
      if (regex.test(this.newPin)) {
        return "รหัสผ่านเป็นตัวเลขเท่านั้น";
      }
      return true;
    },
    disabled() {
      return this.rules !== true;
    },
  },
};
</script>

<style>
</style>