<template>
  <v-card flat>
    <v-card-title>
      <h5><v-icon class="mr-2">mdi-account</v-icon> Login</h5>
    </v-card-title>
    <v-card-text>
      <RfidCardReader
        v-if="!hasUser"
        :label="label"
        outlined
        @onReadHex="onReadHex($event)"
        :loading="fetching"
        :rules="rules"
        ref="rfid"
        hideDetails
        :autofocus="autofocus"
      ></RfidCardReader>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon @click="submit">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import RfidCardReader from "@/components/RfidCardReader.vue";

export default {
  props: {
    label: {
      type: String,
      default: "Withdrawer",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasUser: false,
      hasPin: false,
      loading: false,
      fetching: false,
      cardId: "",
      user: "",
      rules: [true],
    };
  },
  components: {
    RfidCardReader,
  },
  watch: {},
  methods: {
    onReadHex(data) {
      this.submit(data);
    },
    resetForm() {
      this.user = "";
      this.hasUser = false;
      this.hasPin = false;
    },
    async submit(data) {
      if (!data) return;
      this.fetching = true;
      const post = {
        rfid: data,
      };
      const r = await axios.post("/user/search-rfid", post);
      this.fetching = false;
      if (r.data.success) {
        this.$emit("setUser", r.data);
        this.rules = [true];
      } else {
        // this.$emit("setUser", "Login");
        this.rules = ["Employee not found."];
        this.$refs.rfid.focus();
      }
      this.cardId = null;
    },
    clear() {
      this.user = "";
    },
  },
};
</script>

<style></style>