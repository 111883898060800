<template>
  <span>
    <v-btn icon @click="show = true">
      <v-icon>mdi-briefcase</v-icon>
    </v-btn>
    <v-dialog v-model="show" width="500" scrollable>
      <v-card>
        <v-card-title> Job Info </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(value, key) in job" :key="key">
              <v-list-item-title class="text-capitalize">
                {{ key }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ value }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    job() {
      return this.$store.state.job;
    },
  },
};
</script>

<style>
</style>