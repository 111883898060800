import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    job: null,
    template: null,
    jwt: null,
  },
  mutations: {
    setUser(state, value) {
      state.user = value;
    },
    setJob(state, value) {
      state.job = value;
    },
    setTemplate(state, value) {
      state.template = value;
    },
    setJwt(state, value) {
      state.jwt = value;
    },
  },
  actions: {},
  modules: {},
});
