<template>
  <v-card>
    <v-card-text>
      <v-progress-circular
        :rotate="-90"
        :size="120"
        :width="12"
        color="primary"
        :value="value"
      >
        {{ value }}
      </v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    value() {
      let total = 0;
      let complete = 0;
      if (!this.$store.state.template) return 0;
      this.$store.state.template.processes.forEach((process) => {
        process.tasks.forEach((task) => {
          if (task.mechanic.complete) complete++;
          total++;
        });
        if (process.inspector.complete) complete++;
        total++;
      });
      return Math.floor((complete * 100) / total);
    },
  },
};
</script>

<style>
</style>