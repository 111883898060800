<template>
  <span>
    <v-btn @click="show = true">Change pin</v-btn>
    <v-dialog v-model="show" persistent width="400">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          <h5>Change Pin</h5>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Current Pin"
                  v-model="currentPin"
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="New Pin"
                  v-model="newPin"
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Retype Pin"
                  v-model="retypePin"
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="submit"> submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="snackbar" width="200">
      <v-card>
        <v-card-text>
          <v-container fluid> Pin changed successfully. </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      currentPin: "",
      newPin: "",
      retypePin: "",
      snackbar: false,
      loading: false,
    };
  },
  watch: {
    show() {
      this.reset();
    },
  },
  methods: {
    reset() {
      this.currentPin = "";
      this.newPin = "";
      this.retypePin = "";
    },
    async submit() {
      if (this.newPin !== this.retypePin) return;
      if (!this.currentPin || !this.newPin || !this.retypePin) return;
      const post = {
        currentPin: this.currentPin,
        newPin: this.newPin,
        rfid: this.$store.state.jwt.user,
      };
      this.loading = true;
      const r = await axios.put("/user/update-rfid-pin", post);
      this.loading = false;
      console.log(r.data);
      if (!r.data.success) {
        return;
      } else {
        this.snackbar = true;
        this.reset();
        this.show = false;
      }
    },
  },
};
</script>

<style>
</style>