<template>
  <v-card :loading="loading">
    <v-card-title>
      <h5>Tools</h5>
      <v-btn class="mx-1" color="primary" icon @click="populate">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="show = true" color="primary">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="tools.tools"
        :headers="headers"
        hide-default-footer
        dense
      >
        <template v-slot:item.calibrationDate="{ item }">{{
          parsedDate(item.calibrationDate)
        }}</template>
        <template v-slot:item.delete="{ item }">
          <v-btn icon @click="del(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <add-tools
        :show.sync="show"
        @updated="populate"
        :existingTools="tools.tools"
      ></add-tools>
    </v-card-text>
  </v-card>
</template>

<script>
import addTools from "./addTools.vue";
import axios from "axios";
import moment from "moment";

export default {
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      tools: [],
      headers: [
        { text: "Description", value: "description" },
        { text: "Serial Number", value: "serialNumber" },
        { text: "Tool Id", value: "toolId" },
        { text: "Delete", value: "delete" },
      ],
    };
  },
  components: {
    addTools,
  },
  created() {
    this.populate();
  },
  watch: {
    "$route.params.jobnumber"() {
      this.populate();
    },
  },
  methods: {
    async populate() {
      this.loading = true;
      const r = await axios.get(
        "/api/tools/jobnumber/" + this.$route.params.jobnumber
      );

      this.tools = r.data;
      console.log(r.data);
      // if (!r.data.tools) {
      //   this.$emit("hasTools", false);
      // } else {
      //   this.$emit("hasTools", this.tools.tools.length);
      // }
      this.loading = false;
    },
    parsedDate(data) {
      return moment(data).format();
    },
    // async del(item) {
    //   const options = {
    //     title: "Remove tool from this work order?",
    //     message: `Are you sure you want to <strong>permanently</strong> remove:<br><br><strong>${item.description}</strong> serial number <strong>${item.serialNumber}</strong><br><br>from this work order?`,
    //     icon: "delete",
    //     confirmText: "confirm",
    //   };
    //   if (!(await this.$refs.deleteDialog.confirmed(options))) return;
    //   const post = {
    //     jobnumber: this.$route.params.title,
    //     toolId: item.toolId,
    //   };
    //   fetch("/api/workorders/tools", {
    //     method: "delete",
    //     body: JSON.stringify(post),
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   }).then(() => {
    //     this.$refs.deleteDialog.manualClose();
    //     this.populate();
    //   });
    // },
  },
};
</script>
