function jwt(key = "jwt-workorder-service") {
  try {
    const k = document.cookie.split(";").map((x) => {
      const cookie = x.trim();
      const [key, value] = cookie.split("=");
      return { key, value };
    });
    const user = k.find((x) => x.key == key).value;
    const payload = user.split(".")[1];
    return JSON.parse(atob(payload));
  } catch (err) {
    return null;
  }
}

export default jwt;
