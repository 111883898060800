<template>
  <v-container fluid>
    <h5>Processes</h5>
    <v-list
      dense
      v-for="(process, index) in processes"
      :key="index"
      class="py-0"
    >
      <v-list-group color="black">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon v-if="process.inspector.complete" color="green">
              mdi-check-all
            </v-icon>
            <v-progress-circular
              v-else
              :value="getPercent(process)"
              rotate="-90"
              color="primary"
              class="progress"
            ></v-progress-circular>
          </v-list-item-icon>
          <v-list-item-title>{{ process.title }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="(task, i) in process.tasks"
          :key="i"
          @click="$router.push({ query: { process: index + 1, task: i + 1 } })"
        >
          <v-list-item-icon>
            <v-icon v-if="task.mechanic.complete" color="green">
              mdi-check
            </v-icon>
            <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle v-text="task.title"></v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-if="process.inspector.complete" color="green">
              mdi-check
            </v-icon>
            <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle
            v-text="`Inspector verification`"
          ></v-list-item-subtitle>
        </v-list-item>
      </v-list-group>
      <v-divider></v-divider>
    </v-list>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    getPercent(item) {
      let total = 1;
      let complete = 0;
      item.tasks.forEach((x) => {
        if (x.mechanic.complete) complete++;
        total++;
      });
      if (item.inspector.complete) complete++;
      return (complete * 100) / total;
    },
  },
  computed: {
    processes() {
      return this.$store.state.template.processes;
    },
    width() {
      return (window.innerWidth * 30) / 100 - 1;
    },
    height() {
      return window.innerHeight - 48;
    },
  },
};
</script>

<style>
.card-text {
  height: 100%;
  position: absolute;
  overflow-y: auto;
}

.progress {
  margin-top: -4px;
}
</style>