<template>
  <EmployeeBadgeScanner
    v-if="!hasUser"
    ref="rfid"
    label="แสกนบัตรพนักงาน"
    @setUser="setUser"
  ></EmployeeBadgeScanner>
  <PinInput
    v-else
    @onPinInput="onPinInput"
    @onError="onError"
    :user="user"
  ></PinInput>
</template>

<script>
import EmployeeBadgeScanner from "./EmployeeBadgeScanner";
import PinInput from "./PinInput";
import jwt from "@/user.js";

export default {
  data() {
    return {
      title: "Login",
      loading: false,
      hasUser: false,
    };
  },
  components: { EmployeeBadgeScanner, PinInput },
  async created() {},
  mounted() {},
  methods: {
    setUser(val) {
      if (val.success) {
        this.hasUser = true;
        this.user = val;
      }
    },
    onPinInput(val) {
      this.setJwt();
      if (val.position === "MECHANIC") {
        this.$emit("hasUser");
      } else {
        this.$router.push("/list");
      }
    },
    onError() {
      this.hasUser = false;
      this.user = null;
    },
    setJwt() {
      const JWT = jwt();
      this.$store.commit("setJwt", JWT);
    },
  },
};
</script>

<style>
</style>