class Rfid {
  constructor(raw) {
    this.raw = raw;
  }

  toHex() {
    if (this.raw.indexOf(":") > -1) return this.raw;
    if (this.raw.length < 10) return;
    let hex = parseInt(this.raw).toString(16);
    let arr = [];
    for (let i = hex.length - 1; i > -1; i -= 2) {
      let str = (hex[i - 1] || "0") + (hex[i] || "0");
      arr.push(str);
    }
    return arr.join(":").toUpperCase();
  }
}

export { Rfid };
