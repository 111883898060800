<template>
  <span>
    <v-btn
      small
      class="mx-1"
      color="primary"
      @click="approve"
      :loading="loading"
    >
      <v-icon small left>mdi-check</v-icon>approve
    </v-btn>
  </span>
</template>

<script>
import axios from "axios";

export default {
  props: {
    jobnumber: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async approve() {
      this.loading = true;
      const post = {
        jobnumber: this.jobnumber,
        user: this.$store.state.jwt.user,
      };
      const r = await axios.put("/api/workorders-approve", post);
      this.loading = false;
      this.$emit("updated", r.data);
    },
  },
};
</script>

<style>
</style>