<template>
  <div>
    <v-app-bar color="primary" dense app dark>
      <v-btn icon @click="goHome">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="populate">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <UserMenu display="name"></UserMenu>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-0 ma-0">
        <v-row dense>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="-1"
              hide-default-footer
              :loading="loading"
            >
              <template v-slot:item.jobnumber="{ item }">
                <router-link v-if="item.approver" :to="`wo/${item.jobnumber}`">
                  {{ item.jobnumber }}
                </router-link>
                <span v-else>{{ item.jobnumber }}</span>
              </template>
              <template v-slot:item.requiredInspector="{ item }">
                <span v-if="item.complete"> Complete </span>
                <v-chip small v-else-if="item.requiredInspector" color="error">
                  Required
                </v-chip>
                <v-chip small v-else color="success" dark>In process</v-chip>
              </template>
              <template v-slot:item.reviewer="{ item }">
                <v-icon v-if="item.reviewer" color="success">mdi-check</v-icon>
                {{ item.reviewer.user.name }}
              </template>
              <template v-slot:item.approver="{ item }">
                <span v-if="item.approver">
                  <v-icon color="success">mdi-check</v-icon>
                  {{
                    `${item.approver.user.firstName} ${item.approver.user.lastName}`
                  }}
                </span>
                <Approver
                  v-else
                  :jobnumber="item.jobnumber"
                  @updated="item.approver = $event"
                ></Approver>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="remove(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon @click="consoleLog(item)">
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
                <DeleteWorkorder
                  :jobnumber="item.jobnumber"
                  :show.sync="showDelete"
                  @onDelete="populate"
                ></DeleteWorkorder>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="peek" width="800" persistent scrollable>
        <v-card>
          <v-card-title>
            Workorder Info
            <v-spacer></v-spacer>
            <v-btn icon @click="peek = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <pre>{{ pre }}</pre>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UserMenu from "@/components/UserMenu";
import DeleteWorkorder from "@/components/DeleteWorkorder";
import Approver from "./Approver";

import jwt from "@/user.js";

import io from "socket.io-client";

export default {
  components: { UserMenu, DeleteWorkorder, Approver },
  data() {
    return {
      socket: null,
      loading: false,
      headers: [
        { text: "Jobnumber", value: "jobnumber" },
        { text: "Creation Date", value: "creationDate" },
        { text: "Customer", value: "customer" },
        { text: "Component", value: "workorder.component" },
        { text: "Service", value: "workorder.service" },
        { text: "Reviewer", value: "reviewer" },
        { text: "Approver", value: "approver" },
        { text: "Status", value: "status" },
        { text: "Required Inspector", value: "requiredInspector" },
        { text: "Actions", value: "actions" },
      ],
      items: [],
      showDelete: false,
      peek: false,
      pre: null,
    };
  },
  created() {
    const JWT = jwt();
    this.$store.commit("setJwt", JWT);
    this.populate();
    this.socket = io();
    //     process.env.NODE_ENV == "development"
    //       ? io("http://localhost:3333")
    //       : io();
  },
  mounted() {
    this.socketApiHandler();
  },
  methods: {
    socketApiHandler() {
      // this.socket.on("connect", () => {
      //   console.log(`socket connected on ${moment().format()}`);
      // });
      this.socket.on("workorders_updated", () => {
        this.populate();
      });
    },
    goHome() {
      this.$router.replace("/");
    },
    async populate() {
      this.loading = true;
      const r = await axios.get("/api/workorders");
      r.data.forEach((job) => {
        job.creationDate = moment(job.creationDate).format(
          "YYYY-MM-DD hh:mm A"
        );
        for (let i = 0; i < job.workorder.processes.length; i++) {
          if (i == job.workorder.processes.length - 1) {
            job.status = job.workorder.processes[i].title;
            let complete = job.workorder.processes[i].tasks.map(
              (x) => x.mechanic.complete
            );
            if (complete.every((x) => x)) {
              job.requiredInspector = true;
            }

            if (job.workorder.processes[i].inspector.complete) {
              job.complete = true;
              job.status = "Complete";
            }
            return;
          }

          if (!job.workorder.processes[i].inspector.complete) {
            job.status = job.workorder.processes[i].title;
            let complete = job.workorder.processes[i].tasks.map(
              (x) => x.mechanic.complete
            );
            if (complete.every((x) => x)) {
              job.requiredInspector = true;
            }
            return;
          }
        }
      });
      this.items = r.data.filter((x) => !x.complete);
      this.loading = false;
    },
    async remove() {
      this.showDelete = true;
    },
    consoleLog(item) {
      this.pre = item;
      this.peek = true;
      console.log(item);
    },
  },
};
</script>

<style>
</style>